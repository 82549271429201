<template>
    <input type="file" @change="onChangeFile" name="file" />
</template>

<script>
export default {

 methods: {
    onChangeFile(event) {
      this.$emit('update:modelValue', event.target.files)
    }
    }
}

</script>

<style>

</style>